import classes from './SliderArea.module.css';

import Slider from '@mui/material/Slider';

const SliderArea = (props) => {

    const handleRSliderChange = (event, newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        props.onRChange(newValue);
    }

    const handleLSliderChange = (event, newValue) => {
        if ((newValue < 0) || (newValue > 100)) {
            return;
        }
        props.onLChange(newValue);
    }
    return (
        <div className={classes.sliderblock}>
            <div>
                <label>Left Third Party ({props.LValue}% of Democrats' Vote):</label>
                <Slider value={props.LValue} step={1} marks min={0} max={100} onChange={handleLSliderChange} />
            </div>
            <div>
                <label>Right Third Party ({props.RValue}% of Republicans' Vote):</label>
                <Slider value={props.RValue} step={1} marks min={0} max={100} onChange={handleRSliderChange} />
            </div>
        </div>
    )
}

export default SliderArea;