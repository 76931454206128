import TopIcons from './TopIcons';

import classes from './Header.module.css';

const Header = (props) => {
    return (
        <div className={classes.header}>
            <div className={classes.maintitle}>
                Third Party Electoral Vote Simulator
            </div>
            <TopIcons LValue={props.LValue} RValue={props.RValue} />
        </div>
    )
}

export default Header;