import { getVoteColor } from '../utility/utilityFunctions';

import classes from './MapSidebar.module.css';

const MapSidebar = (props) => {

    const filteredMap = props.InitialMap.filter(StateObj => StateObj.name.includes('-') || StateObj.name.includes('DC'));

    return (
        <div>
            <div className={classes.mapsidebar}>
                {filteredMap.map((StateObj) => {
                        const voteColor = getVoteColor(StateObj.democrats, StateObj.republicans, props.RValue, props.LValue);
                        return (
                            <div key={StateObj.name} className={classes[voteColor]}>
                                {StateObj.name}
                            </div>
                        );
                })}
            </div>
        </div>
    );
};

export default MapSidebar;