import Header from './Header';
import SliderArea from './SliderArea';
import Map from './Map';
import { useEffect, useState } from 'react';
import ElectoralVotes from './ElectoralVotes';

import { Initial_Map_State, calculateElectoralVotes } from '../utility/utilityFunctions';

import classes from './MainPage.module.css';
import MapSidebar from './MapSidebar';
import { useParams } from 'react-router-dom';


const MainPage = () => {
    const [RTotal, setRTotal] = useState(0);
    const [LTotal, setLTotal] = useState(0);

    const params = useParams();
    useEffect(() => {
        let leftInitial = params.leftInitial || 0;
        if (isNaN(leftInitial) || leftInitial < 0) {
            leftInitial = 0;
        }
        if (leftInitial > 100) {
            leftInitial = 100;
        }
        leftInitial = Math.floor(leftInitial); // Prevent float
        
        let rightInitial = params.rightInitial || 0;
        if (isNaN(rightInitial) || rightInitial < 0) {
            rightInitial = 0;
        }
        if (rightInitial > 100) {
            rightInitial = 100;
        }
        rightInitial = Math.floor(rightInitial); // Prevent float
        setLTotal(leftInitial);
        setRTotal(rightInitial);
    }, [params]);

    const MapData = Initial_Map_State();

    const handleRChange = (newValue) => {
        setRTotal(newValue);
    }

    const handleLChange = (newValue) => {
        setLTotal(newValue);
    }

    const electoralVotes = calculateElectoralVotes(MapData, RTotal, LTotal);

    return (
        <div>
            <Header LValue={LTotal} RValue={RTotal} />
            <SliderArea LValue={LTotal} RValue={RTotal} onRChange={handleRChange} onLChange={handleLChange} />
            <ElectoralVotes voteTotals={electoralVotes} />
            <div className={classes.mapcontainer}>
                <div className={classes.map}>
                    <Map LValue={LTotal} RValue={RTotal} InitialMap={MapData} />
                </div>
                <div className={classes.mapsidebar}>
                    <MapSidebar LValue={LTotal} RValue={RTotal} InitialMap={MapData} />
                </div>
            </div>
        </div>
    )
}

export default MainPage;