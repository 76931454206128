import { Initial_Map_State, calculateElectoralVotes } from '../utility/utilityFunctions';

import classes from './ElectoralVotes.module.css';
import ElectoralVoteSection from './ElectoralVoteSection';
 
const ElectoralVotes = (props) => {
    const MapData = Initial_Map_State();
    const originalElectoralVotes = calculateElectoralVotes(MapData, 0, 0);

    return (
        <div className={classes.electoralvotes}>
            <div className={classes.leftThirdParty}>
                <ElectoralVoteSection partyName="Left Third Party" totalEVs={props.voteTotals.LeftThirdParty} originalEVs={originalElectoralVotes.LeftThirdParty} />
            </div>
            <div className={classes.democrats}>
                <ElectoralVoteSection partyName="Democrats" totalEVs={props.voteTotals.Democrats} originalEVs={originalElectoralVotes.Democrats} />
            </div>
            <div className={classes.ties}>
                <ElectoralVoteSection partyName="Ties" totalEVs={props.voteTotals.Ties} originalEVs={originalElectoralVotes.Ties} />
            </div>
            <div className={classes.republicans}>
                <ElectoralVoteSection partyName="Republicans" totalEVs={props.voteTotals.Republicans} originalEVs={originalElectoralVotes.Republicans} />
            </div>
            <div className={classes.rightThirdParty}>
                <ElectoralVoteSection partyName="Right Third Party" totalEVs={props.voteTotals.RightThirdParty} originalEVs={originalElectoralVotes.RightThirdParty} />
            </div>
        </div>
    )
}

export default ElectoralVotes;